import { module as m } from 'modujs';
import * as basicLightbox from 'basiclightbox';
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.width = StoreSingleton.isMobile ? 350 : 860;
        this.height = StoreSingleton.isMobile ? 197 : 483;

        // EVENT
        this.events = {
            click: '_openIframe'
        }
    }

    init() {}

    _openIframe(event) {
        event.preventDefault();
        if(this.el.dataset.videoid) {
            const _url = this.el.dataset.videoid;
            const instance = basicLightbox.create(`<iframe src="${_url}?autoplay=1&rel=0" width="${this.width}" height="${this.height}" frameborder="0" allowfullscreen></iframe>`)
            instance.show()
        }
    }
}
