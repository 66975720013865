import { module as m } from 'modujs';
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default class extends m  {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            click: {
                'scroll': '_scrollTo',
                'video': '_showVideo'
            }
        }
    }

    /**
     * INIT
     */
    init() {
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * START
     */
    start() {}

    /**
     * DESTROY
     */
    destroy() {}

    /**
     * SCROLL
     */
    _scrollTo() {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: '.section-5',
                autoKill: false,
                offset: 100
            },
            ease: 'power4.inOut'
        });
    }

    _showVideo() {
        gsap.to('#home .section-4 .column-6 .over', {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out'
        });
    }
}
