import { module as m } from 'modujs';
import modularLoad from 'modularload';
import { html } from './../../utils/environment';
import gsap from 'gsap';
import { findValueByPrefix } from "./../../utils/utils"
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.isReady = false;
        this.currentLoadImage = null;
    }

    init() {

        const load = new modularLoad({
            enterDelay: 0,
            reloadOnContextChange: true
        });

        /**
         * LOADING
         */
        load.on('loading', (transition, oldContainer) => {
            console.log("**** LOADING ****", transition, oldContainer);
        });

        /**
         * LOADED
         */
        load.on('loaded', (transition, oldContainer, newContainer) => {
            console.log("**** LOADED ****", transition, oldContainer, newContainer);

            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            // Remove
            html.classList.remove('has-dom-ready');
        });

        /**
         * READY
         */
        load.on('ready', (transition, newContainer) => {
            console.log("**** READY ****", transition, newContainer);

            window.scroll(0, 0);

            setTimeout(() => {
                this.startPage(newContainer.querySelector('[data-page]').dataset);
                html.classList.add('has-dom-ready');
            }, 100);
        });

        /**
         * ALL IMAGES LOADED
         */
        load.on('images', () => {
            console.log("**** ALL IMAGES OK ****");
        });
    }

    /**
     * Start page
     * @param i__data
     */
    startPage(i__modules) {
        if(i__modules) {
            const _pageClass = findValueByPrefix(i__modules, 'module');
            this.call('start', {}, _pageClass);
        }
    }
}
