import { module as m } from 'modujs';
import Swiper, { Navigation } from 'swiper';
import 'swiper/css';

export default class extends m {
    constructor(m) {
        super(m);
    }

    init() {
        console.log('YEAH');
        const swiper = new Swiper(this.el, {
            modules: [ Navigation ],
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    }
}
