import { module as m } from 'modujs';
import gsap from "gsap";

export default class extends m {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.dom.menu = document.querySelector('#menu-mobile');
        this.dom.openMobile = document.querySelector('#header .button-open-mobile');
        this.dom.closeMobile = document.querySelector('#menu-mobile .menu-mobile__top .close');
    }

    init() {

        // WINDOW
        window.addEventListener('scroll', () => {
           if(window.scrollY > 10) {
               document.querySelector('#header').classList.add('white');
           } else {
               document.querySelector('#header').classList.remove('white');
           }
        });

        // EVENTS
        this.dom.openMobile.addEventListener('click', () => {
            gsap.to(this.dom.menu, {
                duration: 0.6,
                autoAlpha: 1,
                ease: 'power4.out'
            });
        });

        this.dom.closeMobile.addEventListener('click', () => {
            gsap.to(this.dom.menu, {
                duration: 0.6,
                autoAlpha: 0,
                ease: 'power4.out'
            });
        });
    }
}
